import React from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-localize-redux';
import { Modal, ModalBody, ModalFooter, Button} from 'reactstrap';
import { connect } from 'react-redux';
import { Endpoint } from '../../constants';
import './ScreenSize.scss';

class ScreenSize extends React.Component {
  constructor(props) {
    super(props);

    const tempMediaQuery = window.matchMedia('(min-width: 48em)'); 

    this.state = {
      desktopMediaQuery: tempMediaQuery,
      modalOpen: !tempMediaQuery.matches
    };

    if(props.openCallback && !tempMediaQuery.matches) {
      props.openCallback();
    }

    this.close = this.close.bind(this);
    this.validateScreenSize = this.validateScreenSize.bind(this);
    this.state.desktopMediaQuery.addListener(this.validateScreenSize);
  }

  componentWillUnmount() {
    const { desktopMediaQuery } = this.state;

    desktopMediaQuery.removeListener(this.validateScreenSize);
  }

  static getDerivedStateFromProps(props, state) {
    if (state.modalOpen) {
      return {
        modal: props.isOpen,
      };
    }

    return null;
  }

  close() {
    this.setState({
      modalOpen: false,
    });
    
    this.props.openCallback();
  }

  validateScreenSize() {
    const { openCallback } = this.props;
    const {desktopMediaQuery} = this.state;

    if (!desktopMediaQuery.matches) {
      this.setState({ modalOpen: true }, () => {
          openCallback && openCallback();
      });
    } else {
      this.setState({ modalOpen: false }, () => {
          openCallback && openCallback();
      });
    }
  }

  render() {
    const { modalOpen } = this.state;
    const { otterAlt } = this.props;

    return (
      <div>
        <Modal wrapClassName="screen-size" isOpen={modalOpen} toggle={this.close}>
          <ModalBody>
            <h1>
              <Translate id="shell-string-size-dialog-title" />
            </h1>

            <img
              alt={otterAlt}
              src={`${Endpoint.CDN_URL}/fundamentals/app/learning-zone/images/Shell/otter-size.png`}
            />
            <p>
              <Translate id="shell-string-size-dialog-copy" />
            </p>
          </ModalBody>
          <ModalFooter>
          <Button className="close" color="primary" onClick={this.close}>
            <Translate id="shell-string-size-dialog-close" />
          </Button>
        </ModalFooter>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  otterAlt: state.languageWidget.translation['shell-string-size-dialog-image-alt'],
});

ScreenSize.propTypes = {
  openCallback: PropTypes.func,
  otterAlt: PropTypes.string.isRequired,
};

ScreenSize.defaultProps = {
  openCallback: () => {}
};

export default connect(mapStateToProps)(ScreenSize);
