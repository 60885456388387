import React, { Fragment } from 'react';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { togglePause } from '../../../Redux/actions/pauseManagerActions';
import Header from '../../../Components/Header/Header';
import ScreenSize from '../../../Components/ScreenSize/ScreenSize';

import './HeaderOnlyTemplate.scss';

class HeaderOnlyTemplate extends React.PureComponent {
  render() {
    const { children, startLanguage } = this.props;

    return (
      <Fragment>
        <Helmet htmlAttributes={{ lang: startLanguage, class: startLanguage }} />
        <Header startLanguage={startLanguage} />
        <div className="HeaderOnlytTemplate headerOnlyBackground">{children}</div>
        <ScreenSize openCallback={togglePause} />
      </Fragment>
    );
  }
}

HeaderOnlyTemplate.propTypes = {
  children: PropTypes.node.isRequired,
  startLanguage: PropTypes.string.isRequired,
  togglePause: PropTypes.func.isRequired,
  audio: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  startLanguage: state.languageWidget.startLanguage,
  audio: state.audioWidget.audio,
});

export default withRouter(
  connect(
    mapStateToProps,
    { togglePause }
  )(HeaderOnlyTemplate)
);
