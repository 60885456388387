import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Translate } from 'react-localize-redux';
import { toggleAudio } from '../../Redux/actions/audioWidgetActions';
import './SoundWidget.scss';

class SoundWidget extends Component {
  constructor(props) {
    super(props);

    this.audioEvent = new Event('audio-change');

    this.handleToggleAudio = this.handleToggleAudio.bind(this);
  }

  componentDidMount() {
    const { audio } = this.props;

    window.sessionStorage.setItem('_eb_sound', audio);
  }

  handleToggleAudio() {
    const { toggleAppAudio } = this.props;

    document.getElementsByClassName('sound-btn')[0].focus();
    toggleAppAudio();
    window.dispatchEvent(this.audioEvent);
  }

  render() {
    const { audio, toggleAudioIconAlt, audioLabelOn, audioLabelOff } = this.props;
    const audioGraphic = audio ? `/images/Shell/audio_on.svg` : `/images/Shell/audio_off.svg`;

    const audioLabel = audio ? audioLabelOn : audioLabelOff;

    return (
      <div className="SoundWidget">
        <button
          type="button"
          aria-pressed={audio}
          onClick={this.handleToggleAudio}
          className="sound-btn"
          aria-label={audioLabel}
          title={audioLabel}
        >
          <img src={audioGraphic} alt={toggleAudioIconAlt} aria-hidden="true" />
          <span className="sr-only">
            <Translate id="shell-sr-toggle-audio" />
          </span>
        </button>
      </div>
    );
  }
}

SoundWidget.propTypes = {
  audio: PropTypes.bool.isRequired,
  toggleAppAudio: PropTypes.func.isRequired,
  toggleAudioIconAlt: PropTypes.string.isRequired,
  audioLabelOn: PropTypes.string.isRequired,
  audioLabelOff: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  audio: state.audioWidget.audio,
  toggleAudioIconAlt: state.languageWidget.translation['shell-alt-toggle-audio'],
  audioLabelOn: state.languageWidget.translation['shell-audio-text-on'],
  audioLabelOff: state.languageWidget.translation['shell-audio-text-off'],
});

export default connect(
  mapStateToProps,
  { toggleAppAudio: toggleAudio }
)(SoundWidget);
