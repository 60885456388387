import React, { Fragment } from 'react';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { togglePause } from '../../../Redux/actions/pauseManagerActions';
import Header from '../../../Components/Header/Header';
import ScreenSize from '../../../Components/ScreenSize/ScreenSize';
import PrimaryNavigation from '../../../Components/PrimaryNavigation/PrimaryNavigation';
import './BrowseTemplate.scss';

class BrowseTemplate extends React.PureComponent {

  render() {
    const { children, startLanguage, togglePause } = this.props;

    return (
      <Fragment>
        <Helmet htmlAttributes={{ lang: startLanguage, class: startLanguage }} />
        <Header startLanguage={startLanguage} />
        <PrimaryNavigation />
        <div className="wrapper browse-template">
          <div className="sixteen-nine">
            <main className="content" id="main-content">
              {children}
            </main>
          </div>
        </div>
        <ScreenSize openCallback={togglePause} />
      </Fragment>
    );
  }
}

BrowseTemplate.propTypes = {
  children: PropTypes.node.isRequired,
  startLanguage: PropTypes.string.isRequired,
  togglePause: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  startLanguage: state.languageWidget.startLanguage,
});

export default connect(
  mapStateToProps,
  { togglePause }
)(BrowseTemplate);
