import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { RoutePath } from '../../constants';
import './BackToHome.scss';

const BackToHome = props => {
  const { brandingHeaderAlt, brandingHeaderPath, backToHomeSr } = props;

  return (
    <Link to={RoutePath.HOME} tabIndex="0" aria-label={backToHomeSr} className="back-to-home">
      <img src={`${brandingHeaderPath}`} alt={brandingHeaderAlt} className="branding" />
    </Link>
  );
};

BackToHome.propTypes = {
  brandingHeaderAlt: PropTypes.string.isRequired,
  brandingHeaderPath: PropTypes.string.isRequired,
  backToHomeSr: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  brandingHeaderAlt: state.languageWidget.translation['shell-alt-branding-header'],
  brandingHeaderPath: state.languageWidget.translation['shell-name-image-header-branding'],
  backToHomeSr: state.languageWidget.translation['shell-sr-back-to-home'],
});

export default connect(mapStateToProps)(BackToHome);
