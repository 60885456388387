import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { createAriaLabelPropKey, setToolbarFocus } from '../PabloUtil';
import AccessibleIconButton from '../../../Components/AccessibleIconButton/AccessibleIconButton';
import { PabloConstants } from '../PabloConstants';
import '../PabloStyles/ToolbarButtonComponent.scss';

class ToolbarButtonComponent extends Component {
  constructor(props) {
    super(props);

    this.printTimeout = null;

    this.handleClick = this.handleClick.bind(this);
    this.$toolbarButtonRef = createRef();
  }

  componentDidMount() {
    const { toolbarItem, ariaChecked, ariaHidden, tabIndex } = this.props;
    const $toolbarButton = this.$toolbarButtonRef.current;

    if (
      toolbarItem !== PabloConstants.TOOL.REDO &&
      toolbarItem !== PabloConstants.TOOL.UNDO &&
      toolbarItem !== PabloConstants.TOOL.CLEAR
    ) {
      $toolbarButton.setAttribute('role', 'radio');
      $toolbarButton.setAttribute('aria-checked', ariaChecked);
    } else {
      $toolbarButton.setAttribute('aria-hidden', ariaHidden);
      $toolbarButton.tabIndex = tabIndex;
    }
  }

  componentDidUpdate() {
    const { ariaChecked, toolbarItem, ariaHidden, tabIndex } = this.props;
    const $toolbarButton = this.$toolbarButtonRef.current;

    if (
      toolbarItem !== PabloConstants.TOOL.REDO &&
      toolbarItem !== PabloConstants.TOOL.UNDO &&
      toolbarItem !== PabloConstants.TOOL.CLEAR
    ) {
      $toolbarButton.setAttribute('aria-checked', ariaChecked);
    } else {
      $toolbarButton.setAttribute('aria-hidden', ariaHidden);
      $toolbarButton.tabIndex = tabIndex;
    }
  }

  componentWillUnmount() {
    if (this.printTimeout) {
      clearTimeout(this.printTimeout);
    }
  }

  handleClick() {
    // Condition click functionality depending on tool
    const { isAnimating } = this.props;

    if (!isAnimating) {
      const {
        setStateBrushSelection,
        setStickerbookBrushSelection,
        print,
        setToolbarSelection,
        // eslint-disable-next-line no-unused-vars
        playSound,
        toggleDrawer,
        toolbarItem,
        stickerbook,
        redo,
        undo,
        clearCanvas,
      } = this.props;

      toggleDrawer(toolbarItem);

      setToolbarFocus(toolbarItem);

      if (
        toolbarItem !== PabloConstants.TOOL.UNDO &&
        toolbarItem !== PabloConstants.TOOL.REDO &&
        toolbarItem !== PabloConstants.TOOL.CLEAR
      ) {
        setToolbarSelection(toolbarItem);
      }

      if (toolbarItem === PabloConstants.TOOL.SET_BACKGROUND) {
        // eslint-disable-next-line no-underscore-dangle
        stickerbook._canvas.isDrawingMode = false;
        // eslint-disable-next-line no-underscore-dangle
        stickerbook._canvas.freeDrawingCursor = 'default';
        // eslint-disable-next-line no-underscore-dangle
        stickerbook._canvas.hoverCursor = 'default';
      }

      // Uncomment to revert back to original sticker logic
      // if (toolbarItem === 'sticker') {
      //   stickerbook.enterStickerMode();
      // }

      if (setStateBrushSelection && setStickerbookBrushSelection) {
        setStateBrushSelection(toolbarItem);
        setStickerbookBrushSelection(toolbarItem);
      }

      // 'Uncomment to turn audio back on';
      // playSound(toolbarItem);

      if (redo) {
        redo();
      } else if (undo) {
        undo();
      }

      if (print) {
        this.printTimeout = setTimeout(print, 1000);
      }

      if (clearCanvas) {
        clearCanvas();
      }
    }
  }

  render() {
    const { toolbarItem, classList, toolbarIcon, toolbarSelection } = this.props;

    return (
      <div className="ToolbarButtonComponent">
        <AccessibleIconButton
          name={toolbarItem}
          elementId={toolbarItem}
          containerClassName={classList}
          className={`toolbarIcon ${classNames({
            defaultTool: toolbarItem === PabloConstants.TOOL.PENCIL,
            '': toolbarItem !== PabloConstants.TOOL.PENCIL,
            activeToolbarIconColor: toolbarItem === toolbarSelection,
          })}`}
          icon={toolbarIcon}
          callback={this.handleClick}
          // eslint-disable-next-line react/destructuring-assignment
          title={this.props[`${createAriaLabelPropKey(toolbarItem, null, 'Sr')}`]}
          refLink={this.$toolbarButtonRef}
        />
      </div>
    );
  }
}

ToolbarButtonComponent.propTypes = {
  toolbarItem: PropTypes.string.isRequired,
  setToolbarSelection: PropTypes.func.isRequired,
  setStateBrushSelection: PropTypes.func,
  setStickerbookBrushSelection: PropTypes.func,
  print: PropTypes.func,
  toggleDrawer: PropTypes.func.isRequired,
  playSound: PropTypes.func.isRequired,
  classList: PropTypes.string,
  toolbarIcon: PropTypes.shape().isRequired,
  toolbarSelection: PropTypes.string.isRequired,
  isAnimating: PropTypes.bool.isRequired,
  stickerbook: PropTypes.shape(),
  redo: PropTypes.func,
  undo: PropTypes.func,
  ariaChecked: PropTypes.string,
  clearCanvas: PropTypes.func,
  ariaHidden: PropTypes.string,
  tabIndex: PropTypes.number,
};

ToolbarButtonComponent.defaultProps = {
  classList: null,
  setStateBrushSelection: null,
  setStickerbookBrushSelection: null,
  print: null,
  stickerbook: null,
  redo: null,
  undo: null,
  ariaChecked: null,
  clearCanvas: null,
  ariaHidden: null,
  tabIndex: null,
};

const mapStateToProps = state => ({
  pencilSr: state.languageWidget.translation['pablo-sr-pencil'],
  markerSr: state.languageWidget.translation['pablo-sr-marker'],
  fillSr: state.languageWidget.translation['pablo-sr-fill'],
  setBackgroundSr: state.languageWidget.translation['pablo-sr-set-background'],
  patternSr: state.languageWidget.translation['pablo-sr-pattern'],
  spraySr: state.languageWidget.translation['pablo-sr-spray'],
  eraserSr: state.languageWidget.translation['pablo-sr-eraser'],
  printSr: state.languageWidget.translation['pablo-sr-print'],
  undoSr: state.languageWidget.translation['pablo-sr-undo'],
  redoSr: state.languageWidget.translation['pablo-sr-redo'],
  clearSr: state.languageWidget.translation['pablo-sr-clear-canvas'],
});

export default connect(mapStateToProps)(ToolbarButtonComponent);
